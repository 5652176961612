<template>
  <v-container class="payment-type payment-type-redirect payment-type-gestpay">
    <div
      class="payment-type-title"
      v-html="paymentType.title"
      v-if="paymentType.title"
    ></div>
    <div
      class="payment-type-description"
      v-html="paymentType.descr"
      v-if="mode != 'addAuth'"
    ></div>
    <div
      v-else
      class="payment-type-description payment-type-description-add-auth"
      v-html="
        $t(`paymentTypeList.addNewPayemntAuth.${paymentType.paymentTypeId}`)
      "
    ></div>
    <div v-if="options.orderAmount" class="mt-3 mb-1">
      <div
        v-html="
          `${$t('paymentTypeList.orderAmount.text')}
      <strong>${$n(options.orderAmount, 'currency')}</strong>`
        "
      />
      <!-- <strong v-html="options.orderAmount.value" /> -->
    </div>
    <PrebilledAmount
      :mode="mode"
      :paymentTypeId="paymentType.paymentTypeId"
      :order-id="orderId"
    />

    <div
      v-if="
        options.showPrebilledAmountInfoTooltip ||
          options.showPrebilledAmountInfoText
      "
      class="prebilledAmountInfo"
    >
      <!-- shows info in form of tooltip on prebilled.info.text span hover -->
      <v-tooltip
        top
        v-if="options.showPrebilledAmountInfoTooltip"
        max-width="30%"
        content-class="payment-type-tooltip payment-type-gestpay-tooltip"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="text" v-bind="attrs" v-on="on">
            {{ $t("prebilled.info.text") }}
          </div>
        </template>
        <span>{{ $t("prebilled.info.tooltip") }}</span>
      </v-tooltip>

      <!-- shows info under prebilled.info.text -->
      <div v-if="options.showPrebilledAmountInfoText">
        <div class="primary--text text--lighten-1">
          {{ $t("prebilled.info.text") }}
        </div>
        <div class="text-body-2">{{ $t("prebilled.info.tooltip") }}</div>
      </div>
      <span class="sub">{{ $t("prebilled.info.sub") }}</span>
    </div>

    <PaymentAuthList
      v-if="options.showRememberCard || mode == 'addAuth'"
      @selectPaymentAuth="selectPaymentAuth"
      @addNewCard="addNewCard"
      @toggleRememberCard="toggleRememberCard"
      :paymentTypeId="paymentType.paymentTypeId"
      @paymentAuthList="paymentAuthList"
      :showAddNewCardWarning="options.showAddNewCardWarning"
      :additionalInfoPayment="additionalInfoPayment"
    />
    <div class="remember-card-container" v-if="options.showRememberCard">
      <v-btn
        color="primary"
        @click="addNewCard"
        small
        v-if="cardList.length > 0"
      >
        {{ $t("paymentTypeList.addNewWallet") }}
      </v-btn>
      <v-checkbox v-model="rememberNewCard" :label="calcRememberNewCard()" />
    </div>

    <div
      v-if="$te('paymentTypeList.redirectText.' + paymentType.paymentTypeId)"
      v-html="$t('paymentTypeList.redirectText.' + paymentType.paymentTypeId)"
      class="redirect-text"
    ></div>
    <ResponseMessage :response="response" />
    <v-row class="d-flex justify-end">
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center"
        v-if="mode != 'order' && !options.hidePrevButton"
      >
        <v-btn
          v-if="options.back.show"
          :color="options.back.color"
          :outlined="options.back.outline"
          :icon="options.back.icon"
          :link="options.back.link"
          depressed
          v-on:click="goBack"
          :disabled="loading"
          :large="options.back.large"
          :class="options.back.class"
        >
          {{ options.back.label }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn
          v-if="options.confirm.show"
          :color="options.confirm.color"
          :outlined="options.confirm.outline"
          class="mt-3 mt-sm-0 ml-2 gateway-btn"
          v-on:click="buy"
          :depressed="options.confirm.depressed"
          :large="options.confirm.large"
          :class="options.confirm.class"
        >
          <span
            v-if="
              additionalInfoPayment === 'WALLET_PAYPAL' &&
                options.showAddNewCardWarning == false
            "
          >
            {{ $t("paymentTypeList.addNewWalletPaypal") }}
          </span>
          <span v-else> {{ options.confirm.label }} </span>
          <!-- {{ options.confirm.label }} -->
        </v-btn>
      </v-col>
    </v-row>
    <i18n
      v-if="$te(`paymentTypeList.${paymentType.gatewayClient}.caption`)"
      :path="`paymentTypeList.${paymentType.gatewayClient}.caption`"
      tag="div"
      class="caption"
    />
  </v-container>
</template>
<script>
import PrebilledAmount from "./PrebilledAmount.vue";
import PaymentAuthList from "./PaymentAuthList.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";

import gateway from "~/mixins/gateway";

import OrderService from "~/service/orderService";
import PaymentTypeService from "~/service/paymentTypeService";

import { payment, handlePayResponseKo } from "./gatewayUtilsService";
import { mapActions } from "vuex";

export default {
  name: "PaymentTypeGestpay",
  mixins: [gateway],
  data() {
    return {
      loading: false,
      error: null,
      selectedPaymentAuthId: null,
      rememberNewCard: this.options.rememberCardDefaultValue || false,
      additionalInfoPayment: "WALLET_PAYPAL",
      response: {},
      cardList: []
    };
  },
  components: {
    PrebilledAmount,
    PaymentAuthList,
    ResponseMessage
  },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart",
      updateCurrentOrderId: "app/updateCurrentOrderId"
    }),
    async buy() {
      let vm = this;
      vm.loading = true;
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        if (this.paymentType.paymentTypeId == 27) {
          paymentData.only_paypal = true;
        } else {
          paymentData.only_paypal = false;
        }

        if (vm.mode == "cart") {
          let cart = await vm.buyCart({
            paymentTypeId: this.paymentType.paymentTypeId,
            userPaymenthAuthId: this.selectedPaymentAuthId,
            paymentData: paymentData
          });
          if (cart) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(cart.order.orderId);
            vm.payResponseOk(cart);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            this.paymentType.paymentTypeId,
            this.selectedPaymentAuthId,
            paymentData
          );
          if (response) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(vm.orderId);
            vm.payResponseOk(response);
          } else {
            this.$router.push("/cart/payment-error");
          }
        } else if (vm.mode == "duedate") {
          //           PaymentDueService.duedatePay(vm.paymentDueDateIds, vm.paymentTypeId, paymentAuthId, paymentData).then(
          //   payResponseOk,
          //   payResponseKo
          // );
        } else if (vm.mode == "addAuth") {
          let addAuthData = await PaymentTypeService.addAuth(
            this.paymentType.paymentTypeId,
            {
              remember_card: true,
              only_paypal: paymentData.only_paypal
            }
          );
          if (addAuthData) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(vm.orderId);
            vm.doRedirect(
              addAuthData.paymentUrl,
              addAuthData.paymentParameters,
              "get",
              null
            );
          } else {
            this.$router.push("/cart/payment-error");
          }
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    async addNewCard() {
      let vm = this;
      vm.loading = true;
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        if (this.paymentType.paymentTypeId == 27) {
          paymentData.only_paypal = true;
        } else {
          paymentData.only_paypal = false;
        }

        if (vm.mode == "cart") {
          let cart = await vm.buyCart({
            paymentTypeId: this.paymentType.paymentTypeId,
            paymentData: paymentData
          });
          if (cart) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(cart.order.orderId);
            vm.payResponseOk(cart);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            vm.paymentType.paymentTypeId,
            paymentData
          );
          if (response) {
            //aggiungo il CURRENT_ORDER_ID al localStorage
            vm.updateCurrentOrderId(vm.orderId);
            vm.payResponseOk(response);
          } else {
            this.$router.push("/cart/payment-error");
          }
        }
      } catch (err) {
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    },
    payResponseOk(cart) {
      let vm = this;
      let orderId = null;
      global.EventBus.$emit("pay-response-ok", cart);
      if (cart && cart.paymentUrl) {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        vm.doRedirect(cart.paymentUrl, cart.paymentParameters, "get", orderId);
        this.loading = false;
      } else {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        this.$router.push("/cart/payment-completed/" + orderId);
        this.loading = false;
      }
    },
    payResponseKo(data) {
      this.loading = false;
      handlePayResponseKo(data, this.orderId);
    },
    doRedirect(paymentUrl, paymentParameters, method, orderId) {
      payment(paymentUrl, paymentParameters, method, orderId);
    },
    goBack() {
      this.$router.push("/checkout");
    },
    selectPaymentAuth(paymentAuthId) {
      this.selectedPaymentAuthId = paymentAuthId;
    },
    toggleRememberCard(value) {
      this.rememberNewCard = value;
    },
    calcRememberNewCard() {
      const rememberNewCard = global.EventBus.$t(
        "paymentTypeList.rememberNewWallet"
      );
      if (typeof rememberNewCard === "string") {
        return rememberNewCard;
      } else {
        return this.cardList.length > 0
          ? rememberNewCard.new
          : rememberNewCard.first;
      }
    },
    paymentAuthList(arr) {
      this.cardList = arr;
    }
  }
};
</script>
